<template>
  <div id="authentication">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <b-row>
        <b-col id="action-area" md="6" offset-md="4">
          <h1 class="text-center">Resend Verification Link</h1>
          <p class="text-center">{{ successMessage }}</p>
          <div
            v-bind:class="{ cognitoError: cognitoResponse }"
            class="text-center"
            v-if="!successMessage"
          >
            {{ cognitoResponse }}
          </div>
          <b-form v-on:submit.prevent="resendConfirmationCode" v-if="!successMessage">
            <b-form-group id="emailGroup">
              <b-form-input
                v-bind:disabled="busy"
                size="lg"
                v-model="email"
                required
                class="Control__input"
                name="email"
                type="email"
                autocomplete="username"
                placeholder="Email Address"
              />
            </b-form-group>
            <b-row>
              <b-button
                v-bind:disabled="busy"
                class="authButton"
                size="lg"
                block
                type="submit"
                variant="primary"
              >
                <div v-if="busy">
                  <Busy light :margin="0" />
                </div>
                <div v-else>Resend Verification</div>
              </b-button>
            </b-row>
          </b-form>
          <p class="text-center">
            Already verified your account?
            <router-link :to="{ name: 'signin' }">Sign in</router-link>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/authentication.scss";
</style>

<script>
import CognitoAuth from "@/cognito/cognitoauth";
import Busy from "@/components/Busy";
import Console from "@/console";

export default {
  name: "resend-verification",
  mounted: function () {
    document.body.className = "authenticationBody";
  },
  destroyed: function () {
    document.body.className = "";
  },
  components: {
    Busy
  },
  data() {
    return {
      title: "Resend Verificatino",
      email: "",
      password: "",
      successMessage: null,
      cognitoResponse: null,
      busy: false
    };
  },
  methods: {
    cognitoCallBack(err, result) {
      Console.log(err, result);
      this.busy = false;
      if (err) {
        switch (err.code) {
          case "UserNotFoundException":
            this.cognitoResponse =
              "Sorry, we could not find a user with that email address, please sign up";
            break;
          default:
            this.cognitoResponse = err.message;
        }
      } else {
        this.successMessage = "We have sent you a new verification link to your email address.";
      }
    },
    resendConfirmationCode() {
      this.busy = true;
      this.cognitoResponse = null;
      CognitoAuth.resendConfirmationCode(this.email.toLowerCase(), this.cognitoCallBack);
    }
  }
};
</script>